import {
    createSlice,
    PayloadAction,
} from "scholastic-client-components/src/SharedPackages/reduxToolkit"
import {APIStatus} from "scholastic-client-components/src/types/APITypes"
import {
    getNotificationSendTypeView,
    IUserNotifications,
    IUserNotificationsGroup,
    UserAppSettings,
    UserSecurity,
} from "scholastic-client-components/src/types/settingsTypes"
import {IUserSettingsAndNotifications} from "scholastic-client-components/src/types/userSettingsTypes"
import {ProfileFormValues} from "../pages/Profile/ProfileForm/ProfileForm"
import {uniqBy} from "lodash"
import {PersonLocale} from "scholastic-client-components/src/slices/authSlice"
import {ProfileMicrosoftSSOResponse} from "../api/userAPI"

export enum PersonType {
    Admin = "ADMIN",
    User = "USER",
    Student = "STUDENT",
    Provider = "PROVIDER",
}

export interface Profile {
    groupEdit: boolean
    person: {
        id: number
        firstName: string
        lastName: string
        email: string
        phone: string
        type: PersonType
        selectedRoles?: any[]
        locale?: PersonLocale
    }
    availableRoles?: any[]
    locales: PersonLocale[]
    settings: Partial<IUserSettingsAndNotifications>
}

interface AvailableSettings {
    appSettings?: UserAppSettings[]
    security?: UserSecurity[]
    notifications?: IUserNotificationsGroup[]
}

interface SliceState {
    fetchStatus: APIStatus
    saveStatus: APIStatus
    data?: Profile
    availableSettings?: AvailableSettings
    initialValues?: ProfileFormValues
    profileMicrosoftSSOAPIStatus: APIStatus
}

const initialState: SliceState = {
    fetchStatus: APIStatus.Initial,
    saveStatus: APIStatus.Initial,
    profileMicrosoftSSOAPIStatus: APIStatus.Initial,
}

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        startFetch(state: SliceState) {
            state.fetchStatus = APIStatus.Loading
        },
        failFetch(state: SliceState) {
            state.fetchStatus = APIStatus.Failure
        },
        successFetch(state: SliceState, action: PayloadAction<Profile>) {
            state.data = action.payload
            if (!state.data.locales) {
                state.data.locales = []
            }
            state.availableSettings = getFormattedSettings(action.payload)
            state.initialValues = getInitialValues(action.payload)
            state.fetchStatus = APIStatus.Success
        },
        startSave(state: SliceState) {
            state.saveStatus = APIStatus.Loading
        },
        failSave(state: SliceState) {
            state.saveStatus = APIStatus.Failure
        },
        successSave(state: SliceState, action: PayloadAction<Profile>) {
            state.data = action.payload
            state.availableSettings = getFormattedSettings(action.payload)
            state.initialValues = getInitialValues(action.payload)

            state.saveStatus = APIStatus.Success
        },
        setProfileMicrosoftSSOAPIStatus(state: SliceState, action: PayloadAction<APIStatus>) {
            state.profileMicrosoftSSOAPIStatus = action.payload
        },
        setMicrosoftSSO(state: SliceState, action: PayloadAction<ProfileMicrosoftSSOResponse>) {
            if (state.data?.settings) {
                state.data.settings.microsoftSSO = action.payload.microsoftSSO
            }
        },
        reset() {
            return initialState
        },
    },
})

interface Store {
    profile: SliceState
}

export const profileSelectors = {
    getFetchStatus: (store: Store) => store.profile.fetchStatus,
    getSaveStatus: (store: Store) => store.profile.saveStatus,
    getData: (store: Store) => store.profile.data,
    getAvailableSettings: (store: Store) => store.profile.availableSettings,
    getInitialValues: (store: Store) => store.profile.initialValues,
    getProfileMicrosoftSSOAPIStatus: (store: Store) => store.profile.profileMicrosoftSSOAPIStatus,
}

const getFormattedSettings: (profile: Profile) => AvailableSettings = (profile) => {
    const {appSettings, security, notifications} = profile.settings
    return {
        appSettings: appSettings && (Object.keys(appSettings) as UserAppSettings[]),
        security: security && (Object.keys(security) as UserSecurity[]),
        notifications: notifications,
    }
}

const getInitialValues: (profile: Profile) => ProfileFormValues = (profile) => {
    return {
        ...profile.person,
        appSettings: profile.settings.appSettings,
        security: profile.settings.security || {},
        notifications:
            profile.settings.notifications?.reduce((res, {items}) => {
                return Object.assign(
                    res,
                    uniqBy(items.map((item) => item.items).flat(), "type").reduce(
                        (obj, {type, sendType}) => {
                            obj[type] = {
                                id: sendType,
                                name: getNotificationSendTypeView()[sendType]?.name,
                            }
                            return obj
                        },
                        {} as IUserNotifications
                    )
                )
            }, {} as IUserNotifications) || {},
    }
}

import i18next from "i18next"
import {initReactI18next} from "react-i18next"
import resourcesToBackend from "i18next-resources-to-backend"
import "moment/locale/ru.js"

import en_US from "antd/lib/locale/en_US"
import ru_RU from "antd/lib/locale/ru_RU"
import moment from "moment"

export const antdLocale = {
    en: en_US,
    ru: ru_RU,
}

const defaultNamespaces = [
    "common",
    "glossary",
    "person",
    "home_portal",
    "shared_components",
    "greetings",
    "content",
    "comments",
]

type I18nextInstanceConfig = {
    ns: string[]
    lng?: string
}

export const i18n = i18next.createInstance()

export const initI18n = async (config: I18nextInstanceConfig): Promise<void> => {
    const ns = [...defaultNamespaces, ...config.ns]
    const lng = config.lng
    const fallbackLng = "en"

    moment.updateLocale(lng || fallbackLng, {
        week: {
            dow: 1,
        },
    })

    moment.locale(lng || fallbackLng)

    await i18n
        .use(
            resourcesToBackend(
                (language: string, namespace: string) =>
                    import(`./locales/${language}/${namespace}.json`)
            )
        )
        .use(initReactI18next)
        .init({
            ns,
            lng: lng, // if you're using a language detector, do not define the lng option
            fallbackLng: fallbackLng,
            interpolation: {
                escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            },
            debug: false,
            preload: lng ? [lng] : [fallbackLng],
            partialBundledLanguages: true,
            react: {
                transSupportBasicHtmlNodes: true,
            },
        })
}

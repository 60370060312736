import {NameView} from "./view/common/NameView"
import {i18n} from "../i18n/i18n"

export interface TVSettingsI extends NameView {
    isDefault?: boolean
    updateInterval: number
    mainDuration: number
    leftDuration: number
    feedScrollPercent: number
    feedExpirePeriod: number
    feedFontSize: number
    feedFontType: string
    alertInterval: number
    advDuration: number
    rebootTime: number
    rebootDays: Array<number>
    restartTime: number
    restartDays: Array<number>
    notificationTime: number
    notificationDays: Array<number>
    fontTypeList: Array<string>
    buildingSettings?: Array<number>
    locationSettings?: Array<number>
}

export interface TVSettingsViewI extends Omit<TVSettingsI, "feedFontType" | "fontTypeList"> {
    feedFontType: {id: string; name: string}
    fontTypeList: Array<{id: string; name: string}>
}

export enum UserAppSettings {
    AutoOpenDownloadWindow = "autoOpenDownloadWindow",
}

export enum UserNotifications {
    //partner notifications
    AD_EXPIRE_SOON = "AD_EXPIRE_SOON",
    AD_THREE_MONTHS_UNCHANGED = "AD_THREE_MONTHS_UNCHANGED",
    AD_APPROVED = "AD_APPROVED",
    AD_REJECTED = "AD_REJECTED",
    AD_EXPIRED = "AD_EXPIRED",
    PARTNER_CONTENT_APPROVAL = "PARTNER_CONTENT_APPROVAL",
    MARKET_ACCESS_CHANGED = "MARKET_ACCESS_CHANGED",

    // content notifications
    SCHEDULED_CONTENT_OFFLINE = "SCHEDULED_CONTENT_OFFLINE",
    STUDENT_CONTENT_APPROVAL = "STUDENT_CONTENT_APPROVAL",
    STUDENT_PARTNER_CONTENT_APPROVAL = "STUDENT_PARTNER_CONTENT_APPROVAL",
    STUDENT_GLOBAL_CONTENT_APPROVAL = "STUDENT_GLOBAL_CONTENT_APPROVAL",
    PROVIDER_CONTENT_APPROVAL = "PROVIDER_CONTENT_APPROVAL",
    CONTENT_EXPIRE_SOON = "CONTENT_EXPIRE_SOON",

    // devices notifications
    DEVICE_OFFLINE = "DEVICE_OFFLINE",
    DEVICE_TRIAL_PERIOD_STARTED_ADMIN = "DEVICE_TRIAL_PERIOD_STARTED_ADMIN",
    DEVICE_TRAIL_PERIOD_EXPIRED_ADMIN = "DEVICE_TRAIL_PERIOD_EXPIRED_ADMIN",
    DEVICE_TRIAL_PERIOD_EXPIRES_SOON = "DEVICE_TRIAL_PERIOD_EXPIRES_SOON",
    HIGHER_ED_DEVICE_TRIAL_PERIOD_EXPIRES_SOON = "HIGHER_ED_DEVICE_TRIAL_PERIOD_EXPIRES_SOON",
    BUSINESS_DEVICE_TRIAL_PERIOD_EXPIRES_SOON = "BUSINESS_DEVICE_TRIAL_PERIOD_EXPIRES_SOON",
    DEVICE_TRIAL_PERIOD_EXPIRED = "DEVICE_TRIAL_PERIOD_EXPIRED",
    HIGHER_ED_DEVICE_TRIAL_PERIOD_EXPIRED = "HIGHER_ED_DEVICE_TRIAL_PERIOD_EXPIRED",
    BUSINESS_DEVICE_TRIAL_PERIOD_EXPIRED = "BUSINESS_DEVICE_TRIAL_PERIOD_EXPIRED",
    DEVICE_TRIAL_14 = "DEVICE_TRIAL_PERIOD_EXPIRE_IN_14_DAYS",
    DEVICE_TRIAL_7 = "DEVICE_TRIAL_PERIOD_EXPIRE_IN_7_DAYS",
    DEVICE_TRIAL_3 = "DEVICE_TRIAL_PERIOD_EXPIRE_IN_3_DAYS",
    DEVICE_TRIAL_1 = "DEVICE_TRIAL_PERIOD_EXPIRE_IN_1_DAYS",
    SCREEN_OFFLINE = "SCREEN_OFFLINE",
    SCREEN_CREATED = "SCREEN_CREATED",
    SCREEN_BROADCASTING_ENDED = "SCREEN_BROADCASTING_ENDED",
    ANNOUNCEMENT_STARTED = "ANNOUNCEMENT_STARTED",
    ALERT_ONLINE = "ALERT_ONLINE",
    ALERT_OFFLINE = "ALERT_OFFLINE",
    LIVE_FEED_ONLINE = "LIVE_FEED_ONLINE",
    RTC_FEED_ONLINE = "RTC_FEED_ONLINE",

    // projects notifications
    GLOBAL_PROJECT_CREATED = "GLOBAL_PROJECT_CREATED",
    PARTNER_PROJECT_CREATED = "PARTNER_PROJECT_CREATED",
    PROJECT_AVAILABLE = "PROJECT_AVAILABLE",
    STUDENT_LOCAL_PROJECT_CREATED = "STUDENT_LOCAL_PROJECT_CREATED",
    STUDENT_GLOBAL_PROJECT_CREATED = "STUDENT_GLOBAL_PROJECT_CREATED",
    STUDENT_SPONSOR_PROJECT_CREATED = "STUDENT_SPONSOR_PROJECT_CREATED",
    STUDENT_GLOBAL_CONTENT_APPROVED = "STUDENT_GLOBAL_CONTENT_APPROVED",
    STUDENT_SPONSOR_CONTENT_APPROVED = "STUDENT_SPONSOR_CONTENT_APPROVED",
    CONTENT_APPROVED = "CONTENT_APPROVED",
    CONTENT_REJECTED = "CONTENT_REJECTED",
    SCHOOL_USE_CONTENT = "SCHOOL_USE_CONTENT",
    PARTNER_USE_CONTENT = "PARTNER_USE_CONTENT",
    PROJECT_SHARED = "PROJECT_SHARED",

    // libraries notifications
    LOCAL_LIBRARY_CREATED = "LOCAL_LIBRARY_CREATED",
    GLOBAL_LIBRARY_ENABLE = "GLOBAL_LIBRARY_ENABLE",
    PREMIUM_LIBRARY_ENABLE = "PREMIUM_LIBRARY_ENABLE",

    // provider notifications
    PROVIDER_LOCAL_LIBRARY_ENABLE = "PROVIDER_LOCAL_LIBRARY_ENABLE",
    PROVIDER_GLOBAL_LIBRARY_ENABLE = "PROVIDER_GLOBAL_LIBRARY_ENABLE",
    PROVIDER_PREMIUM_LIBRARY_ENABLE = "PROVIDER_PREMIUM_LIBRARY_ENABLE",
    PROVIDER_CONTENT_APPROVED = "PROVIDER_CONTENT_APPROVED",
    PROVIDER_CONTENT_REJECTED = "PROVIDER_CONTENT_REJECTED",
    PREMIUM_LIBRARY_VERSION_APPROVAL = "PREMIUM_LIBRARY_VERSION_APPROVAL",
    PREMIUM_LIBRARY_VERSION_APPROVED = "PREMIUM_LIBRARY_VERSION_APPROVED",
    PREMIUM_LIBRARY_VERSION_REJECTED = "PREMIUM_LIBRARY_VERSION_REJECTED",
    PREMIUM_LIBRARY_VERSION_IS_ACTIVE = "PREMIUM_LIBRARY_VERSION_IS_ACTIVE",

    // newline notifications
    NEWLINE_SCHOOL_TO_REAL = "NEWLINE_SCHOOL_TO_REAL",
    NEWLINE_SCHOOL_CREATED = "NEWLINE_SCHOOL_CREATED",
    CUSTOMER_SCHOOL_CREATED = "CUSTOMER_SCHOOL_CREATED",
    CUSTOMER_DISTRICT_CREATED = "CUSTOMER_DISTRICT_CREATED",
    CUSTOMER_BUILDING_CREATED = "CUSTOMER_BUILDING_CREATED",
    CUSTOMER_HIGHER_ED_DISTRICT_CREATED = "CUSTOMER_HIGHER_ED_DISTRICT_CREATED",
    CUSTOMER_HIGHER_ED_BUILDING_CREATED = "CUSTOMER_HIGHER_ED_BUILDING_CREATED",

    // support request notifications
    SUPPORT_REQUEST_RESPONSE = "SUPPORT_REQUEST_RESPONSE",
    BUSINESS_SUPPORT_REQUEST_RESPONSE = "BUSINESS_SUPPORT_REQUEST_RESPONSE",
    HIGHER_ED_SUPPORT_REQUEST_RESPONSE = "HIGHER_ED_SUPPORT_REQUEST_RESPONSE",
    SPONSOR_SUPPORT_REQUEST_RESPONSE = "SPONSOR_SUPPORT_REQUEST_RESPONSE",
    PROVIDER_SUPPORT_REQUEST_RESPONSE = "PROVIDER_SUPPORT_REQUEST_RESPONSE",
    SUPPORT_REQUEST_CREATED = "SUPPORT_REQUEST_CREATED",
    SUPPORT_REQUEST_NEW_SUPPORT = "SUPPORT_REQUEST_NEW_SUPPORT",

    //business notifications
    BUSINESS_CONTENT_EXPIRE_SOON = "BUSINESS_CONTENT_EXPIRE_SOON",
    SCHEDULED_BUSINESS_CONTENT_OFFLINE = "SCHEDULED_BUSINESS_CONTENT_OFFLINE",
    BUSINESS_SCREEN_CREATED = "BUSINESS_SCREEN_CREATED",
    BUSINESS_SCREEN_OFFLINE = "BUSINESS_SCREEN_OFFLINE",
    BUSINESS_SCREEN_BROADCASTING_ENDED = "BUSINESS_SCREEN_BROADCASTING_ENDED",
    BUSINESS_ALERT_ONLINE = "BUSINESS_ALERT_ONLINE",
    BUSINESS_ALERT_OFFLINE = "BUSINESS_ALERT_OFFLINE",
    BUSINESS_LIVE_FEED_ONLINE = "BUSINESS_LIVE_FEED_ONLINE",
    BUSINESS_RTC_FEED_ONLINE = "BUSINESS_RTC_FEED_ONLINE",
    BUSINESS_LOCAL_LIBRARY_CREATED = "BUSINESS_LOCAL_LIBRARY_CREATED",
    BUSINESS_GLOBAL_LIBRARY_ENABLE = "BUSINESS_GLOBAL_LIBRARY_ENABLE",
    BUSINESS_PREMIUM_LIBRARY_ENABLE = "BUSINESS_PREMIUM_LIBRARY_ENABLE",
    CUSTOMER_BUSINESS_CREATED = "CUSTOMER_BUSINESS_CREATED",
    BUSINESS_PROJECT_CREATED = "BUSINESS_PROJECT_CREATED",
    STUDENT_BUSINESS_PROJECT_CREATED = "STUDENT_BUSINESS_PROJECT_CREATED",
    STUDENT_BUSINESS_CONTENT_APPROVED = "STUDENT_BUSINESS_CONTENT_APPROVED",

    //higher ed notifications
    HIGHER_ED_ALERT_ONLINE = "HIGHER_ED_ALERT_ONLINE",
    HIGHER_ED_ALERT_OFFLINE = "HIGHER_ED_ALERT_OFFLINE",
    HIGHER_ED_LIVE_FEED_ONLINE = "HIGHER_ED_LIVE_FEED_ONLINE",
    HIGHER_ED_RTC_FEED_ONLINE = "HIGHER_ED_RTC_FEED_ONLINE",
    HIGHER_ED_SCREEN_CREATED = "HIGHER_ED_SCREEN_CREATED",
    HIGHER_ED_SCREEN_OFFLINE = "HIGHER_ED_SCREEN_OFFLINE",
    HIGHER_ED_SCREEN_BROADCASTING_ENDED = "HIGHER_ED_SCREEN_BROADCASTING_ENDED",
    HIGHER_ED_SCHEDULED_CONTENT_OFFLINE = "HIGHER_ED_SCHEDULED_CONTENT_OFFLINE",
    HIGHER_ED_CONTENT_EXPIRE_SOON = "HIGHER_ED_CONTENT_EXPIRE_SOON",
    HIGHER_ED_LOCAL_LIBRARY_CREATED = "HIGHER_ED_LOCAL_LIBRARY_CREATED",
    HIGHER_ED_GLOBAL_LIBRARY_ENABLE = "HIGHER_ED_GLOBAL_LIBRARY_ENABLE",
    HIGHER_ED_PREMIUM_LIBRARY_ENABLE = "HIGHER_ED_PREMIUM_LIBRARY_ENABLE",
    STUDENT_HIGHER_ED_CONTENT_APPROVAL = "STUDENT_HIGHER_ED_CONTENT_APPROVAL",
    HIGHER_ED_PROJECT_CREATED = "HIGHER_ED_PROJECT_CREATED",
    HIGHER_ED_PROVIDER_CONTENT_APPROVAL = "HIGHER_ED_PROVIDER_CONTENT_APPROVAL",

    //translation failed notifications
    SCHOOL_SCHEDULED_TRANSLATION_FAILED = "SCHOOL_SCHEDULED_TRANSLATION_FAILED",
    BUSINESS_SCHEDULED_TRANSLATION_FAILED = "BUSINESS_SCHEDULED_TRANSLATION_FAILED",
    HIGHER_ED_SCHEDULED_TRANSLATION_FAILED = "HIGHER_ED_SCHEDULED_TRANSLATION_FAILED",
}

export enum NotificationSendType {
    all = "all",
    system_push = "system_push",
    email = "email",
    none = "none",
    default = "default",
}

export enum UserSecurity {
    TFA = "tfa",
}

export type IUserAppSettings = {
    [key in UserAppSettings]?: boolean
}

export interface IUserNotification {
    type: UserNotifications
    sendType: NotificationSendType
}

export enum NotificationSectionType {
    HELP = "HELP",
    ADMIN = "ADMIN",
    SCREENS = "SCREENS",
    CONTENT = "CONTENT",
    REPOSITORY = "REPOSITORY",
    STUDENT = "STUDENT",
    MESSAGES = "MESSAGES",
    PROVIDER = "PROVIDER",
}

export interface IUserNotificationsGroup {
    name: string
    items: Array<{
        name: NotificationSectionType
        items: Array<IUserNotification>
    }>
}

export type IUserSecurity = {
    [key in UserSecurity]?: boolean
}

export type IUserNotifications = {
    [p in UserNotifications]?: {
        id: NotificationSendType
        name: string
    }
}

export const getNotificationSendTypeView = () => ({
    all: {id: NotificationSendType.all, name: i18n.t("notifications:method.all")},
    email: {
        id: NotificationSendType.email,
        name: i18n.t("notifications:method.email"),
    },
    system_push: {
        id: NotificationSendType.system_push,
        name: i18n.t("notifications:method.system_push"),
    },
    none: {
        id: NotificationSendType.none,
        name: i18n.t("notifications:method.none"),
    },
    default: {
        id: NotificationSendType.default,
        name: i18n.t("notifications:method.default"),
    },
})

import React, {FC, memo, ReactNode} from "react"
import {Icon, NameIcon} from "../../ui/Icon/Icon"
import styles from "./CRUDSuccessPopup.module.scss"
import {Box} from "../../ui/Box/Box"
import classNames from "classnames"
import CloseIcon from "./graphics/close.svg"
import {ReactComponent as InstitutionIcon} from "./graphics/institution.svg"
import {ReactComponent as BusinessIcon} from "./graphics/business.svg"
import {ReactComponent as DistrictIcon} from "./graphics/district.svg"
import {ReactComponent as BuildingTypeIcon} from "./graphics/building_type.svg"
import {ReactComponent as BuildingIcon} from "./graphics/building.svg"

export interface CRUDSuccessPopupProps {
    children?: ReactNode
    onClose?: () => void
    iconName?: NameIcon
    icon?: ReactNode
    classes?: string
    /**
     * One of the icons predefined for the crud popup
     */
    crudIcon?: CRUDIconProps["crudIcon"]
}

export const CRUDSuccessPopup: FC<CRUDSuccessPopupProps> = memo(
    ({onClose, iconName, icon, children, classes, crudIcon}) => (
        <Box className={classNames(styles.popup, classes)}>
            {iconName && <Icon name={iconName} className={styles.icon} />}
            {icon}
            {crudIcon && <CRUDIcon crudIcon={crudIcon} className={styles.crudIcon} />}
            <div className={styles.textBox}>{children}</div>
            {onClose && (
                <button onClick={onClose} className={styles.closeButton}>
                    <img src={CloseIcon} alt={"Close"} />
                </button>
            )}
        </Box>
    )
)

interface CRUDIconProps {
    crudIcon: "institution" | "business" | "district" | "buildingType" | "building"
    className: string
}

const CRUDIcon: React.FC<CRUDIconProps> = ({crudIcon, className}) => {
    switch (crudIcon) {
        case "institution":
            return <InstitutionIcon className={className} />
        case "business":
            return <BusinessIcon className={className} />
        case "district":
            return <DistrictIcon className={className} />
        case "buildingType":
            return <BuildingTypeIcon className={className} />
        case "building":
            return <BuildingIcon className={className} />
        default:
            return null
    }
}

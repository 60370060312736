/**
 * SSO Broadcast Channel
 */
export class SSOBC {
    constructor(private bc = new BroadcastChannel("TSN_SSO")) {}

    postSSOState = (ssoState: string) => {
        this.bc.postMessage({ssoState})
        return this
    }

    spawnSSOWindow = (config: {
        url: string
        onCancel: () => void
        onState: (SSOState: string) => void
    }) => {
        const h = 600
        const w = 650
        const y = window.outerHeight / 2 + window.screenY - h / 2
        const x = window.outerWidth / 2 + window.screenX - w / 2
        const SSOWindow = window.open(
            config.url,
            "_blank",
            `popup,width=${w},height=${h},top=${y},left=${x}`
        )
        if (SSOWindow) {
            const interval = setInterval(() => {
                if (SSOWindow.closed) {
                    clearInterval(interval)
                    config.onCancel
                }
            }, 1000)

            new SSOBC().subscribeToSSOState((SSOState) => {
                clearInterval(interval)
                config.onState(SSOState)
                SSOWindow.close()
            })
        }
    }

    subscribeToSSOState = (cb: (ssoState: string) => void) => {
        this.bc.onmessage = (event) => {
            cb(event.data?.ssoState)
            this.bc.close()
        }
    }

    close = () => {
        this.bc.close()
    }
}

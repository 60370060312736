import React from "react"
import {APIStatus} from "../../types/APITypes"
import {EditableCommentProps} from "./components/EditableCommentField/EditableCommentField"

export const NEW_COMMENT_ID = -1
export const COLORED_USER_NAME = "#003A58"

export type OnEditComment = (params: {
    commentId: number
    newComment: string
    files: CommentFile[]
    onSuccess: () => void
}) => void

export type OnDeleteComment = (params: {commentId: number; onSuccess: () => void}) => void

export interface ChatProps<T extends CommentMessage> {
    comments: T[]
    customCommentRender?: {
        [status: string]: React.FC<T>
    }
    creatorId?: number
    CommentFooter?: React.FC<T>
    EditableCommentFooter?: React.FC<Partial<T & CommentMessage>>

    colorfulUserName?: string
    hideNewMessageField?: boolean
    withCounter?: number | {count: number; name?: "comment" | "message"}
    sendNewComment?: boolean
    hideUploadBlock?: boolean
    textAreaLabel?: string
    saveButtonText?: string

    useDedicatedFlags?: boolean

    hooks: HooksType

    onEdit?: OnEditComment
    editStatuses?: {
        [id: number]: APIStatus
    }

    // onSuccess is to close confirm after request
    onDelete?: OnDeleteComment
    deleteStatus?: APIStatus
    phone?: string
    upload?: EditableCommentProps["upload"]
    alwaysShowPhone?: boolean
    commentLabel?: string
}

export interface CommentFile {
    id?: number
    file: string
    type: string
    name: string
    path: string
}

export interface CommentMessage {
    id?: number
    message: string | React.ReactNode
    creator?: {
        id?: number
        fullName: string | React.ReactNode
        firstName?: string
        lastName?: string
        phone?: string | number
    }
    created?: number | string
    editDate?: number
    files?: Array<CommentFile>

    isEditable?: boolean
    allowEdit?: boolean
    allowDelete?: boolean
    isNewMessage?: boolean

    commentToEdit?: string

    // status is for customCommentRender
    status?: string
    setPristine?: (value: boolean) => void
}

export interface HooksType {
    useSelector: any
    dispatch: any
}
